import React from 'react'
import styles from './Indicators.module.css'
import classNames from 'classnames/bind'
import fixNum from '../../pages/Func.wallet/fixNum'

export const Indicators = ({ percent, usd }) => {
	const variants = {
		green: styles.green,
		red: styles.red,
	}

	return (
		<div
			className={classNames(
				styles.row,
				variants[percent > 0 ? 'green' : 'red']
			)}>
			<div className={styles.text}>
				{percent > 0 ? '+' : ''}
				{fixNum(usd)}
			</div>
			<div className={styles.block}>
				{percent > 0 ? '+' : ''}
				{fixNum(percent)}%
			</div>
		</div>
	)
}
