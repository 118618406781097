import React from 'react'
import {
	Button,
	Menu,
	ItemSelect,
	ButtonActions,
	PriceInputGroup,
} from '../../components'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
	setChooseCoinOne,
	setChooseCoinTwo,
} from '../../redux/slices/TransactionsSlice'
import { useDispatch } from 'react-redux'

export const Swap = () => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const { chooseCoinOne, chooseCoinTwo } = useSelector(
		(state) => state.transaction
	)
	const [value, setValue] = React.useState('')
	const [valueOut, setValueOut] = React.useState('')
	const [network, setNetwork] = React.useState('Bitcoin')
	React.useEffect(() => {
		if (value !== '') {
			const usdCount = chooseCoinOne.market_data.current_price * +value
			setValueOut(usdCount / chooseCoinTwo.market_data.current_price)
		} else {
			setValueOut('')
		}
	}, [value])

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
			if (state.from == 'swapTwo') {
				dispatch(setChooseCoinTwo(state.item))
			}
		}
	}, [state])

	const onSwap = () => {
		if (chooseCoinOne !== null && chooseCoinTwo !== null) {
			const frst = chooseCoinOne
			const scnd = chooseCoinTwo
			dispatch(setChooseCoinOne(scnd))
			dispatch(setChooseCoinTwo(frst))
		}
	}

	const onChoosePercent = (value) => {
		if (chooseCoinOne != null) {
			setValue(
				((chooseCoinOne.market_data.balance / 100) * Number(value)).toString()
			)
		}
	}

	const mockList = [
		{
			title: 'Blockchain Network',
			active: true,
		},
		{
			title: 'Ethereum Mainnet',
		},
		{
			title: 'BNB Smart Chain',
		},
		{
			title: 'Avalanche Network',
		},
		{
			title: 'Arbitrum Internal',
		},
	]

	const buttonActions = [
		{
			title: '20%',
		},
		{
			title: '50%',
		},
		{
			title: '75%',
		},
		{
			title: '100%',
		},
	]

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner'>
				<ItemSelect
					setNetwork={setNetwork}
					title={network}
					subtitle='Swap in:'
					dropdownList={mockList}
				/>
				<div>
					<PriceInputGroup
						valueTwo={valueOut}
						valueOne={value}
						setValueOne={setValue}
						onSwap={onSwap}
						chooseItemOne={chooseCoinOne}
						chooseItemTwo={chooseCoinTwo}
					/>
					<ButtonActions onClick={onChoosePercent} actions={buttonActions} />
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' icon='change'>
					Swap
				</Button>
			</div>
		</div>
	)
}
