import React from 'react'
import {
	ItemBlock,
	Checkbox,
	Button,
	Item,
	Popup,
	Input,
	Header,
} from '../../components'
import { useSelector } from 'react-redux'
import { setRpcLinks, setActiveRpcLink } from '../../redux/slices/StorageSlice'
import { useDispatch } from 'react-redux'

export const Rpc = () => {
	const dispatch = useDispatch()
	const { rpcLinks, activeRpcLink } = useSelector((state) => state.storage)
	const [showModalAdd, setShowModalAdd] = React.useState(false)
	const [showModalSuccess, setShowModalSuccess] = React.useState(false)
	const [showModalRestart, setShowModalRestart] = React.useState(false)
	const [disabledBtnAdd, setDisabledBtnAdd] = React.useState(true)
	const [rpcTitle, setRpcTitle] = React.useState('')

	const addNewRpc = () => {
		const newRpcItem = {
			id: Math.random(),
			title: rpcTitle,
			info: {
				ms: '102ms',
				height: 'Height: 16885049',
				status: 'green',
			},
		}
		dispatch(setRpcLinks(newRpcItem))
	}
	const onAddRpc = () => {
		addNewRpc()
		setShowModalAdd(false)
		setShowModalSuccess(true)
		dispatch(setActiveRpcLink(rpcTitle))
	}

	const chooseRpcLink = (link) => {
		dispatch(setActiveRpcLink(link))
		setShowModalRestart(true)
	}

	const closeModalSuccess = () => {
		setShowModalSuccess(false)
		setShowModalRestart(true)
	}

	React.useEffect(() => {
		if (
			(rpcTitle.length > 5 && rpcTitle.toLowerCase().includes('https:')) ||
			(rpcTitle.length > 5 && rpcTitle.toLowerCase().includes('wc:'))
		) {
			setDisabledBtnAdd(false)
		} else {
			setDisabledBtnAdd(true)
		}
	}, [rpcTitle])

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='RPC Node' />

				<Item
					title='Whats the "Height" of nodes?'
					text='The larger number of height means that the nodes have more stable and in-time synchronization. When the nodes are connected at similar speeds, it is recommended to choose the one which has a higher height.'
				/>

				<div className='list-group'>
					<div className='list-title page-row-md'>Custom</div>
					<Button variant='default' onClick={() => setShowModalAdd(true)}>
						Add Custom Node
					</Button>
				</div>
				{rpcLinks.map((item) => (
					<ItemBlock content={item} key={item.id}>
						<Checkbox
							activeItem={activeRpcLink}
							item={item.title}
							onChange={chooseRpcLink}
							type='radio'
							variant='boxed_inline'
							id={`rpc` + item.id}
							name='rpc-list'
						/>
					</ItemBlock>
				))}
				{/*Модалка*/}
				<div>
					<Popup isOpen={showModalAdd} title='Add Custom Node'>
						<div className='page-row'>
							<Input
								handleChange={setRpcTitle}
								inputValue={rpcTitle}
								title='Add node'
								placeholder='https://'
								size='lg'
							/>
						</div>
						<Button
							variant={disabledBtnAdd ? 'disabled' : 'default'}
							onClick={onAddRpc}>
							Add node
						</Button>
						<Button variant='inline' onClick={() => setShowModalAdd(false)}>
							Cancel
						</Button>
					</Popup>

					<Popup
						isOpen={showModalSuccess}
						title='Success'
						text='New custom RPC Node successfully added'>
						<Button variant='default' onClick={closeModalSuccess}>
							Okay
						</Button>
					</Popup>

					<Popup
						isOpen={showModalRestart}
						type='warning'
						title='Restart Required'
						titleTextStyled
						text='To finish switching the RPC node, Arbitrum needs to restart.'>
						<Button variant='default' to='/home'>
							Restart
						</Button>
						<Button variant='inline' onClick={() => setShowModalRestart(false)}>
							Cancel
						</Button>
					</Popup>
				</div>
			</div>
		</div>
	)
}
