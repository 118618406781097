import React, { useState } from 'react'
import styles from './PriceInput.module.css'
import { Box, TheSelect } from '../../components'
import classNames from 'classnames/bind'
import Svg from './../../svgs/Svg'
import { useNavigate } from 'react-router-dom'
import fixNum from '../../pages/Func.wallet/fixNum'

export const PriceInput = ({
	value,
	setValue,
	title,
	chooseItem,
	from,
	disabled,
	to,
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const navigate = useNavigate()
	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleBlur = () => {
		setIsFocused(false)
	}

	return (
		<Box
			className={classNames(styles.box, isFocused ? styles.box_focused : '')}>
			<div className={styles.left}>
				<div
					className={styles.row}
					style={disabled ? { pointerEvents: 'none' } : {}}>
					<span>{title}</span>
					<input
						onChange={(e) => setValue(e.target.value)}
						value={value}
						type='text'
						className={styles.input}
						placeholder='0.0'
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
				</div>
				<div className={styles.line}>
					Balance:
					<span className={styles.subLine}>
						{fixNum(chooseItem.market_data.balance) + ' '}
						{chooseItem.symbol.toUpperCase()}
					</span>
				</div>
			</div>
			{chooseItem !== null && (
				<div
					className='choose-item'
					onClick={() => navigate('/swap-coins', { state: { from, to: to } })}>
					<img src={chooseItem.image.thumb} alt={chooseItem.name} />
					<span>{chooseItem.symbol.toUpperCase()}</span>
					<Svg type='arrow-right' />
				</div>
			)}
		</Box>
	)
}
