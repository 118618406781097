import React from 'react'
import { Button, ImageBlock } from '../../components'

import importImage from '../../assets/images/import-success.png'
import { useSelector } from 'react-redux'

export const ImportAdded = () => {
	const { walletAddress } = useSelector((state) => state.wallet)
	const { password, usePin } = useSelector((state) => state.storage)

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<ImageBlock
					image={importImage}
					title='You have successfully added a new wallet'
					subtitle={walletAddress}
				/>
				<div className='page-align-bottom'>
					<Button
						variant='default'
						to={password !== '' && usePin ? '/home' : '/create-pin'}>
						Finish
					</Button>
				</div>
			</div>
		</div>
	)
}
