import React from 'react'
import styles from './Transaction.module.css'
import { Box, Chart } from '../'
import fixNum from './../../pages/Func.wallet/fixNum'
import { useNavigate } from 'react-router-dom'

export const Transaction = ({ data }) => {
	const navigate = useNavigate()
	return (
		<Box
			style={{ cursor: 'pointer' }}
			onClick={() => navigate('/assets-info', { state: data })}>
			<div className={styles.inner}>
				<div className={styles.logo}>
					<img src={data.image.thumb} alt={data.title} />
				</div>
				<div className={styles.name}>
					{data.symbol}
					<span>{data.name}</span>
				</div>
				{/* <div className={styles.chart}>
					<Chart data={data.chart} color={data.chartColor} />
				</div> */}
				<div className={styles.info}>
					<div style={{ color: '#9AA2B1' }}>
						{fixNum(data.market_data.balance)}
						{' ' + data.symbol}
					</div>
					<span>${fixNum(data.market_data.current_price)}</span>
				</div>
			</div>
		</Box>
	)
}
