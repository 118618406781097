import React from 'react'
import styles from './account.module.css'
import { Menu } from '../../components'
import Svg from '../../svgs/Svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Account = () => {
	const navigate = useNavigate()
	const { backup } = useSelector((state) => state.storage)
	const list = [
		{
			title: 'Wallets',
			to: '/wallets',
		},
		{
			title: 'Import existing wallet',
			to: '/import-start',
		},
		{
			title: 'Backup',
			status: 'success',
			to: '/backup',
		},
		{
			title: 'Security',
			to: '/settings',
		},
		{
			title: 'Notifications',
			to: '/notifications',
		},
		// {
		// 	title: 'Currency',
		// 	to: '/currency',
		// },
		{
			title: 'RPC Node ',
			to: '/rpc',
		},
		{
			title: 'Wallet Connect',
			to: '/dapps',
		},
		{
			title: 'About',
			to: '/about',
		},
	]

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Account Settings</div>
				</div>

				<div className={styles.list}>
					{list.map((item, index) => (
						<div
							className={styles.item}
							key={index}
							onClick={() => navigate(item.to)}>
							{item.title}
							{item.status &&
								(item.status === 'success' && backup ? (
									<Svg type='success' />
								) : item.status === 'success' && !backup ? (
									<Svg type='error' />
								) : (
									''
								))}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
