import React from 'react'
import styles from './ButtonLink.module.css'
import Svg from '../../svgs/Svg'
import { Box } from '../UI/Box/Box'
import classNames from 'classnames/bind'

export const ButtonLink = ({ disabled, title, subtitle, onClick }) => {
	return (
		<Box
			onClick={onClick}
			className={classNames(styles.box, disabled ? styles.disabled : '')}>
			<div className={styles.heading}>
				<span className={styles.title}>{title}</span>
				<Svg type='arrow-right' />
			</div>
			{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
		</Box>
	)
}
