import React from 'react'
import { Button, InputCopy, Menu, Header } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { setAddressTo } from '../../redux/slices/TransactionsSlice'

export const SendAddress = () => {
	const dispatch = useDispatch()
	const { addressTo } = useSelector((state) => state.transaction)
	const [disabledBtn, setDisabledBtn] = React.useState(true)

	React.useEffect(() => {
		if (
			(addressTo !== '' && addressTo.length == 42) ||
			addressTo.length == 34 ||
			addressTo.length == 62
		) {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [addressTo])

	const setValueAddress = (value) => {
		dispatch(setAddressTo(value))
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Send a crypto' />

				<div className='subtitle'>
					Enter the address to which you want to send the crypto.
				</div>

				<InputCopy
					paste
					value={addressTo}
					setValue={setValueAddress}
					label='Recipient`s address'
				/>
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					to='/send-preview'>
					Preview Transaction
				</Button>
			</div>
		</div>
	)
}
