import React from 'react'
import styles from './receive.module.css'
import { Button, InputCopy, Menu, Header } from '../../components'
import Svg from '../../svgs/Svg'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'

export const Receive = () => {
	const { walletAddress } = useSelector((state) => state.wallet)
	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Receive a crypto' />

				<InputCopy label='My Tokenname address' value={walletAddress} />

				<div className={styles.qr}>
					<div className={styles.bg}>
						<Svg type='qr-bg' />
					</div>
					<div className={styles.qr_block}>
						<QRCode
							size={255}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value={walletAddress}
							viewBox={`0 0 256 256`}
						/>
					</div>
				</div>
				<div className={styles.text}>Scan this QR Code</div>
			</div>
		</div>
	)
}
