import React from 'react';
import styles from './TextRowsBlock.module.css'
import { Box } from '../';

export const TextRowsBlock = ({ content }) => {
  return (
    <Box className={styles.block}>
      {content.map((item, index) => (
        <div className={styles.row} key={index}>
          <div>{item.left}</div>
          <div>{item.right}</div>
        </div>
      ))}
    </Box>
  );
}
