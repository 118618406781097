import React from 'react'
import Svg from '../../svgs/Svg'
import styles from './copyButton.module.css'

export const CopyButton = ({ text, style, phrase }) => {
	const handleCopyToClipboard = async (e) => {
		e.preventDefault()
		try {
			await navigator.clipboard.writeText(phrase)
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
	}
	return (
		<a
			href='#'
			onClick={(e) => handleCopyToClipboard(e)}
			className={styles.styleBtn}
			style={style}>
			{text}
			<Svg style={{ marginLeft: 10 }} type='copy' fill='white' />
		</a>
	)
}
