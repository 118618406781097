import React from 'react';
import styles from './welcome.module.css';
import { Button } from '../../components';

import welcome from '../../assets/images/welcome.png';

export const Step3 = () => {
	return (
		<div className='page'>
			<div className='page-inner page-align-bottom'>
				<div className={styles.logo}>
					<img width={200} src={welcome} alt='LNSwap Wallet' />
					<div className={styles.title}>
						Access the world <br /> of cryto & DeFi
					</div>
				</div>
				<Button variant='default' to='/start'>
					Continue
				</Button>
				<Button variant='inline' to='/start'>
					Skip
				</Button>
			</div>
		</div>
	);
};
