import React from 'react';
import styles from './welcome.module.css';
import { Button, Digits, Popup } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetWallet } from '../../redux/slices/StorageSlice';

export const WelcomeBack = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { password } = useSelector((state) => state.storage);
	const { passwordInit } = useSelector((state) => state.wallet);
	const [openReset, setOpenReset] = React.useState(false);
	const [timerIDs, setTimerIDs] = React.useState(null);
	const [timer, setTimer] = React.useState(null);
	const [activeBtn, setActiveBtn] = React.useState(null);
	const [goPage, setGoPage] = React.useState('');
	const [clearPass, setClearPass] = React.useState(false);

	const onLogin = () => {
		if (password === passwordInit) {
			navigate('/home');
		} else {
			setClearPass(true);
			const timerID = setTimeout(() => {
				setClearPass(false);
				clearTimeout(timerID);
			}, 10);
		}
	};

	const onResetWallet = (e, page) => {
		e.preventDefault();
		goDeleteWallets();
		setOpenReset(true);
		setGoPage(page);
	};

	const goDeleteWallets = () => {
		setOpenReset(true);
		setTimer(10);
		setActiveBtn(false);
		setTimerIDs(setInterval(timerDisabled, 1000));
		function timerDisabled() {
			setTimer((state) => {
				if (state == 0) {
					setActiveBtn(true);
					clearInterval(timerIDs);
					return 0;
				} else {
					return state - 1;
				}
			});
		}
	};

	const onreset = () => {
		if (activeBtn) {
			dispatch(resetWallet());
			navigate(`/${goPage}`);
		}
	};

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className={styles.title}>Enter a password</div>
				<Digits name='login' clear={clearPass} />
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' onClick={onLogin}>
					Continue
				</Button>
				<p
					style={{
						color: 'white',
						textAlign: 'center',
						fontSize: 14,
						lineHeight: '20px',
					}}
				>
					Unable to Log in? <br />{' '}
					<a
						onClick={(e) => onResetWallet(e, 'start')}
						href='#'
						style={{ color: 'white' }}
					>
						Import
					</a>{' '}
					or{' '}
					<a
						onClick={(e) => onResetWallet(e, 'start')}
						href='#'
						style={{ color: 'white' }}
					>
						create a new wallet
					</a>
					.
				</p>
			</div>
			<Popup title='Risk Alert' isOpen={openReset}>
				<p
					style={{
						color: 'white',
						textAlign: 'center',
						fontSize: 14,
						lineHeight: '20px',
					}}
				>
					All your existing wallets will be removed and replaced with the new
					one. Please make sure you have backed up your mnemonic phrases
					or private keys, otherwise you will not be able to recover the assets
					in your wallets.
				</p>
				<Button variant='default' onClick={onreset}>
					Continue {activeBtn == false ? `(${timer} s)` : ''}
				</Button>
				<Button variant='inline' onClick={() => setOpenReset(false)}>
					Back
				</Button>
			</Popup>
		</div>
	);
};
