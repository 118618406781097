import React from 'react'
import styles from './home.module.css'
import {
	Menu,
	MenuActions,
	Indicators,
	Button,
	SwipeToggle,
	Transaction,
	ItemImage,
} from '../../components'
import Svg from '../../svgs/Svg'
import fixNum from '../Func.wallet/fixNum'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	setChooseCoinOne,
	setChooseCoinTwo,
} from '../../redux/slices/TransactionsSlice'
import {
	fetchAllCoins,
	fetchDataWallet,
	setAllCoins,
} from '../../redux/slices/WalletSlice'
import { PriceLoader, ItemLoader } from '../../components/Loaders/'

export const Home = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {
		portfolioCoins,
		portfolioBalanceUsd,
		portfolioBalanceChange,
		allCoins,
		dataWallet,
		walletNew,
		coins,
		status,
	} = useSelector((state) => state.wallet)
	const { dataUser, currentWallet, chooseAssets } = useSelector(
		(state) => state.storage
	)
	const [portfolioCoinsFiltered, setPortfolioCoinsFiltered] = React.useState([])

	React.useEffect(() => {
		// dispatch(resetWallet())
		const wallet = dataUser.find((item) => item.name === currentWallet)
		if (wallet !== undefined && dataWallet === null && status !== 'loading') {
			dispatch(fetchDataWallet([wallet.phrase, true]))
		}
	}, [dataUser, dataWallet])

	React.useEffect(() => {
		if (coins === null) {
			dispatch(fetchAllCoins())
		}
	}, [coins])

	React.useEffect(() => {
		if (portfolioCoins !== null && coins !== null) {
			const portfolioSymbols = portfolioCoins.map((item) =>
				item.symbol.toLowerCase()
			)
			const filterAssets = chooseAssets.filter(
				(item) => !portfolioSymbols.includes(item)
			)
			const coinsFilter = coins.filter((item) =>
				filterAssets.includes(item.symbol.toLowerCase())
			)
			const coinsFilterDefault = coins.filter(
				(item) => !portfolioSymbols.includes(item.symbol.toLowerCase())
			)

			setPortfolioCoinsFiltered([...portfolioCoins, ...coinsFilter])

			dispatch(setAllCoins([...portfolioCoins, ...coinsFilterDefault]))
		}
	}, [portfolioCoins, coins, chooseAssets])

	React.useEffect(() => {
		if (allCoins.length) {
			dispatch(setChooseCoinOne(allCoins[0]))
			dispatch(setChooseCoinTwo(allCoins[1]))
		}
	}, [allCoins])

	const menuItems = [
		{
			title: 'Copy Address',
			icon: 'copy',
		},
		{
			title: 'View Recovery Phrase',
			to: '/copy-phrase',
			pass: true,
		},
		{
			title: 'View Private Key',
			to: '/copy-key',
			pass: true,
		},
		{
			title: 'Lock',
			icon: 'lock',
			color: 'var(--red)',
			to: '/welcome-back',
			pass: false,
		},
	]

	const mockNft = [
		{
			image:
				'https://cdn.ordinalswallet.com/inscription/preview/47c1d21c508f6d49dfde64d958f14acd041244e1bb616f9b78114b8d9dc7b945i0',
			title: 'Pixel Pepes #170111-171690',
			subtitle: 'image/png',
			price: '0.06000000',
		},
		{
			image:
				'https://cdn.ordinalswallet.com/inscription/preview/d19981a198e4a8419c8be18588d9e4919cea294deefdb5f9261a30242df578b7i0',
			title: 'Bitcoin Frogs #381224-412389',
			subtitle: 'image/png',
			price: '0.149999',
		},
		{
			image:
				'https://cdn.ordinalswallet.com/inscription/preview/a4dd23ce0651cca0683983dc6411616e8ef9b38658266b641f53b66e2bfc9e7di0',
			title: 'DogePunks #383632-641330',
			subtitle: 'image/png',
			price: '0.00418042',
		},
		{
			image:
				'https://cdn.ordinalswallet.com/inscription/preview/27a7729374514061a18a085b09156d3ead71e2337854f251a0ca0034caaf77eci0',
			title: 'Ordinal Otters #81714-91593',
			subtitle: 'image/png',
			price: '0.01099994',
		},
		{
			image:
				'https://cdn.ordinalswallet.com/inscription/preview/b9b7a284924d74df5e8a405f997d7cad37dadb5949ad687234a22d9e5383f80bi0',
			title: 'Bitcoin Monkes #180273-352484',
			subtitle: 'image/png',
			price: '0.01099994',
		},
	]

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					{portfolioBalanceUsd !== null ? (
						<>
							<div className='title'>$ {fixNum(portfolioBalanceUsd)}</div>
							<MenuActions items={menuItems} />
						</>
					) : (
						<PriceLoader />
					)}
				</div>

				{portfolioBalanceChange !== null ? (
					<Indicators
						usd={portfolioBalanceChange.usd}
						percent={portfolioBalanceChange.percent}
					/>
				) : (
					<></>
				)}

				<div className={styles.actions}>
					<Button
						variant='actions'
						icon='arrow-up'
						onClick={() =>
							navigate('/swap-coins', {
								state: { to: '/send-amount', from: 'swapOne' },
							})
						}>
						Send
					</Button>
					<Button to='/receive' variant='actions' icon='arrow-down'>
						Receive
					</Button>
				</div>

				<SwipeToggle type='buttons' title1='Wallet' title2='Activity' />

				<div className={styles.list}>
					<div className='list-title'>
						Your assets
						<span
							className='list-title-link'
							onClick={() => navigate('/manage-cryptos-main')}>
							Manage
							<Svg type='arrow-right' />
						</span>
					</div>

					{portfolioCoinsFiltered.length ? (
						portfolioCoinsFiltered.map((item, i) => (
							<Transaction data={item} key={i} />
						))
					) : (
						<div className='items-loader'>
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
						</div>
					)}
				</div>

				<div className='list-title'>NFT</div>
				{mockNft.map((item, i) => (
					<ItemImage data={item} key={i} />
				))}
			</div>
		</div>
	)
}
