import React from 'react';
import styles from './send.module.css';
import { Button, Digits, Popup } from '../../components';
import { useLocation } from 'react-router-dom';

export const SendConfirm = () => {
	const { state } = useLocation();

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className={styles.title}>Confirm transaction</div>
				<div className={styles.subtitle}>
					Enter a PIN code to approve your transaction.
				</div>
				<Digits name='sendConfirmFrame' />
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default'>Confirm transaction</Button>
				<Button variant='inline'>Cancel</Button>
			</div>
			<Popup title='Attention' label='Attention modal' text='Not enough funds'>
				<Button variant='default'>Ok</Button>
			</Popup>
			<Popup
				title='Success'
				label='Success modal'
				text='Your transaction has been sent.'
			>
				<Button variant='default'>Ok</Button>
			</Popup>
		</div>
	);
};
