import React, { useRef, useState } from 'react'
import styles from './InputCopy.module.css'
import Svg from '../../svgs/Svg'
import { Input } from '../'

export const InputCopy = ({ label, value, setValue, paste }) => {
	const inputRef = useRef()

	const textRef = React.useRef(null)
	const [timeoutID, setTimeoutID] = React.useState(null)

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(value)
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
	}

	async function pasteWords() {
		let pasteText = textRef?.current
		pasteText.value = ''
		pasteText.focus()
		document.execCommand('paste')

		setTimeoutID(
			setTimeout(() => {
				setValue(pasteText.value)
				clearTimeout(timeoutID)
			}, 10)
		)
	}

	return (
		<div className={styles.input_wrap}>
			<input
				ref={textRef}
				style={{ position: 'absolute', left: -1000, opacity: 0 }}
			/>
			<Input
				title={label}
				placeholder={label}
				iconAlign
				size='lg'
				inputRef={inputRef}
				inputValue={value}
				handleChange={setValue}
			/>
			{paste ? (
				<button onClick={pasteWords} className={styles.copy}>
					<Svg type='copy' />
				</button>
			) : (
				<button onClick={handleCopyToClipboard} className={styles.copy}>
					<Svg type='copy' />
				</button>
			)}
		</div>
	)
}
