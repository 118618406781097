import React, { useState } from 'react'
import Svg from '../../svgs/Svg'
import styles from './Tags.module.css'
import classNames from 'classnames/bind'

export const Tags = ({ content, setPhrase, copy }) => {
	const textRef = React.useRef(null)
	const [tags, setTags] = useState([])
	const [inputValue, setInputValue] = useState('')
	const [timeoutID, setTimeoutID] = React.useState(null)

	const handleInputChange = (e) => {
		setInputValue(e.target.value)
	}

	React.useEffect(() => {
		if (tags.length) {
			setPhrase(tags.join(' '))
		}
	}, [tags])

	const handleInputKeyPress = (e) => {
		if (
			(e.keyCode === 32 && inputValue.trim() !== '') ||
			(e.keyCode === 13 && inputValue.trim() !== '')
		) {
			setTags([...tags, inputValue.trim()])
			setInputValue('')
		}
	}

	const handleTagRemove = (tag) => {
		const updatedTags = tags.filter((t) => t !== tag)
		setTags(updatedTags)
	}

	async function pasteWords() {
		let pasteText = textRef?.current
		pasteText.value = ''
		pasteText.focus()
		navigator.clipboard.readText()
			.then(text => {
				setInputValue('')
				setTags(text.split(' '))
			})
			.catch(err => console.log('Failed to read clipboard contents: ', err))
	}

	const onCopy = async () => {
		try {
			await navigator.clipboard.writeText(content.join(' '))
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
	}

	const onPasteWords = (e) => {
		const timerID = setTimeout(() => {
			setTags(e.target.value.trim().split(' '))
			clearTimeout(timerID)
			setInputValue('')
		}, 10)
	}

	return (
		<div
			className={classNames(
				styles.wrapper,
				content ? styles.not_editable : ''
			)}>
			<input
				ref={textRef}
				style={{ position: 'absolute', left: -1000, opacity: 0 }}
			/>
			{!content ? (
				<div className={styles.inner}>
					{tags.map((tag) => (
						<div key={tag} className={styles.tag}>
							{tag}
							<span
								onClick={() => handleTagRemove(tag)}
								className={styles.delete}>
								<Svg type='close' />
							</span>
						</div>
					))}
					<input
						onPaste={(e) => onPasteWords(e)}
						type='text'
						placeholder='Type here your recovery phrase'
						value={inputValue}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyPress}
						className={styles.input}
					/>
				</div>
			) : (
				<div className={styles.inner}>
					{content.map((item, index) => (
						<span key={index} className={styles.item}>
							{item}
						</span>
					))}
				</div>
			)}
			{copy ? (
				<button className={styles.copy} onClick={onCopy}>
					<Svg type='copy' />
				</button>
			) : (
				<button className={styles.copy} onClick={pasteWords}>
					<Svg type='copy' />
				</button>
			)}
		</div>
	)
}
