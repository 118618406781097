import React from 'react';
import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';
import Svg from '../../svgs/Svg';

export const Header = ({ title, to }) => {
	const navigate = useNavigate();

	return (
		<div className='page-header'>
			<div className='title'>{title}</div>
			<div onClick={() => navigate(to ? to : -1)} className={styles.action}>
				<Svg type='close' />
			</div>
		</div>
	);
};
