import React from 'react'
import styles from './send.module.css'
import {
	Button,
	ButtonActions,
	Menu,
	PriceInput,
	Header,
} from '../../components'
import {
	setChooseCoinOne,
	setChooseCoinTwo,
	setAmountSend,
} from '../../redux/slices/TransactionsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const SendAmount = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const { chooseCoinOne, amountSend } = useSelector(
		(state) => state.transaction
	)
	const [disabledBtn, setDisabledBtn] = React.useState(true)

	React.useEffect(() => {
		if (
			amountSend !== '' &&
			amountSend > 0 &&
			chooseCoinOne.market_data.balance >= amountSend
		) {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [amountSend])

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
			if (state.from == 'swapTwo') {
				dispatch(setChooseCoinTwo(state.item))
			}
		}
	}, [state])

	const buttonActions = [
		{
			title: '20%',
		},
		{
			title: '50%',
		},
		{
			title: '75%',
		},
		{
			title: '100%',
		},
	]

	const onChoosePercent = (value) => {
		if (chooseCoinOne != null) {
			dispatch(
				setAmountSend(
					((chooseCoinOne.market_data.balance / 100) * Number(value)).toString()
				)
			)
		}
	}

	const setValue = (value) => {
		dispatch(setAmountSend(value))
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Send a crypto' />

				<div className='subtitle'>Enter the amount you want to send.</div>

				<div className={styles.row}>
					<PriceInput
						value={amountSend}
						setValue={setValue}
						chooseItem={chooseCoinOne}
						title='Amount:'
						from='swapOne'
						to='/send-amount'
					/>
				</div>
				<ButtonActions onClick={onChoosePercent} actions={buttonActions} />
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					to='/send-address'>
					Continue
				</Button>
			</div>
		</div>
	)
}
