import React from 'react'
import styles from './account.module.css'
import { Button, Tags } from '../../components'
import Svg from '../../svgs/Svg'
import { useSelector } from 'react-redux'
const kitkat = 'Qsx@ah&OR82WX9T6gCt'
import CryptoJS from 'crypto-js'

export const RecoveryPhrase = () => {
	const { dataUser, currentWallet } = useSelector((state) => state.storage)
	const [tags, setTags] = React.useState([])

	React.useEffect(() => {
		if (dataUser.length) {
			const currentWalletData = dataUser.find(
				(item) => item.name == currentWallet
			)
			if (currentWalletData !== undefined) {
				setTags(
					CryptoJS.AES.decrypt(currentWalletData.phrase, kitkat)
						.toString(CryptoJS.enc.Utf8)
						.split(' ')
				)
			} else {
				setTags([])
			}
		}
	}, [dataUser, currentWallet])

	return (
		<div className='page'>
			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Your recovery phrase</div>
				</div>

				<div className='subtitle'>
					Write down or copy these words in right order and save them somewhere
					safe.
				</div>

				{tags.length ? <Tags content={tags} copy /> : <></>}
			</div>

			<div className='page-inner page-align-bottom'>
				<div className={styles.warning}>
					<Svg type='alert' />
					Never share recovery phrase with <br /> anyone, store it securely!
				</div>
				<Button variant='outlined' to={'/home'}>
					Okay
				</Button>
			</div>
		</div>
	)
}
