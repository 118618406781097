import React from 'react';
import styles from './dApps.module.css';
import { Button, Header } from '../../components';

import welcome from '../../assets/images/welcome.png';

export const DAppsStart = () => {
	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<Header title='dApps' />

				<div className={styles.logo_block}>
					<img width={200} src={welcome} alt='Backup Success' />
					<div className={styles.title}>No dApp connected yet.</div>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' to='/dapps-connect'>
					Connect
				</Button>
			</div>
		</div>
	);
};
