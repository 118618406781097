import React from 'react'
import styles from './welcome.module.css'
import { Button } from '../../components'

import logo from '../../assets/images/logo.svg'

export const Start = () => {
	return (
		<div className='page'>
			<div className='page-inner page-align-bottom'>
				<div className={styles.logo}>
					<img src={logo} alt='LNSwap Wallet' />
					<div className={styles.title}>
						Access the world <br /> of cryto & DeFi
					</div>
				</div>
				<Button variant='default' icon='wallet' to='/generate'>
					Create a new wallet
				</Button>
				<Button variant='outlined' icon='import' to='/import'>
					Import existing wallet
				</Button>
			</div>
		</div>
	)
}
