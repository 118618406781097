import React from 'react';
import styles from './ImageBlock.module.css';

export const ImageBlock = ({ widthImg = '70%', title, subtitle, image }) => {
	return (
		<div className={styles.wrapper}>
			<img width={widthImg} style={{ maxWidth: 200 }} src={image} alt={title} />
			<div className={styles.title}>{title}</div>
			<div className={styles.subtitle}>{subtitle}</div>
		</div>
	);
};
