import React, { useState } from 'react'
import styles from './Toggle.module.css'

export const Toggle = ({ id, onToggle, data, checked }) => {
	const handleChange = () => {
		onToggle(data)
	}

	return (
		<div className={styles.toggle}>
			<input
				type='checkbox'
				id={id}
				checked={checked}
				onChange={handleChange}
				className={styles.input}
			/>
			<label htmlFor={id} className={styles.switch} />
		</div>
	)
}
