import React from 'react'
import cn from 'classnames'
import styles from './generateItem.module.css'

export const GenerateItem = ({
	first = false,
	loadWallet = true,
	delay = 10,
	title,
}) => {
	const [showID, setShowID] = React.useState(null)
	const [show, setShow] = React.useState(false)

	React.useEffect(() => {
		if (!loadWallet) {
			setShowID(
				setTimeout(() => {
					setShow(true)
				}, delay)
			)
		}

		return () => clearTimeout(showID)
	}, [loadWallet])

	return (
		<div
			className={cn(styles.item, {
				[styles.showItem]: show || first,
			})}>
			<p className={styles.par}>{title}</p>
		</div>
	)
}
