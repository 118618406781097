import React from 'react'
import styles from './send.module.css'
import { Button, Header, Menu, PriceInput } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setChooseCoinOne } from '../../redux/slices/TransactionsSlice'
import { ItemLoader } from './../../components/Loaders/ItemLoader'

export const Buy = () => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const { chooseCoinOne } = useSelector((state) => state.transaction)
	const [amountBuy, setAmountBuy] = React.useState('')

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
		}
	}, [state])

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Buy a crypto</div>
				</div>

				<div className='subtitle'>Enter the amount you want to buy.</div>

				{chooseCoinOne !== null ? (
					<div className={styles.row}>
						<PriceInput
							from='swapOne'
							to='/buy'
							value={amountBuy}
							setValue={setAmountBuy}
							chooseItem={chooseCoinOne}
							title='Amount:'
						/>
					</div>
				) : (
					<ItemLoader style={{ marginTop: 20 }} />
				)}
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					variant='default'
					onClick={() => window.open('https://www.moonpay.com/buy')}>
					Buy
				</Button>
			</div>
		</div>
	)
}
