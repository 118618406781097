import React from 'react';
import styles from './import.module.css';
import { Button, Input, Popup, Tags } from '../../components';
import {
	setBackup,
	setCurrentWallet,
	setData,
	setIsLogin,
} from '../../redux/slices/StorageSlice';
import { useNavigate } from 'react-router-dom';
import generateAddressesFromSeed from './../../hooks/generateAddress';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { fetchDataWallet, setDataWallet } from '../../redux/slices/WalletSlice';

export const Import = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { dataUser } = useSelector((state) => state.storage);
	const [openModal, setOpenModal] = React.useState(false);
	const [name, setName] = React.useState('Wallet 1');
	const [disabledBtn, setDisabledBtn] = React.useState(true);
	const [disabledBtnName, setDisabledBtnName] = React.useState(true);
	const [showTextSame, setShowTextSame] = React.useState(false);
	const [phrase, setPhrase] = React.useState('');

	React.useEffect(() => {
		if (name !== '' && dataUser.length) {
			const dataUserNames = dataUser.map((item) => item.name);
			if (dataUserNames.includes(name.trim())) {
				setShowTextSame(true);
				setDisabledBtnName(true);
			} else {
				setDisabledBtnName(false);
				setShowTextSame(false);
			}
		} else {
			setDisabledBtnName(false);
		}
	}, [name, dataUser]);

	function createName(names) {
		let i = 1;
		let name = 'Wallet ' + i;
		names.forEach((item) => {
			if (item == name) {
				i = i + 1;
				name = 'Wallet ' + i;
			}
		});
		return name;
	}

	React.useEffect(() => {
		if (dataUser.length) {
			setName(createName(dataUser.map((item) => item.name)));
		}
	}, [dataUser]);

	React.useEffect(() => {
		const phraseArr = phrase.trim().split(' ');
		if (
			(phraseArr.length == 1 && phraseArr.join(' ').length == 64) ||
			phraseArr.length == 12 ||
			phraseArr.length == 15 ||
			phraseArr.length == 18 ||
			phraseArr.length == 21 ||
			phraseArr.length == 24
		) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [phrase]);

	const addNewAcc = () => {
		if (name !== '') {
			const kitkat = 'Qsx@ah&OR82WX9T6gCt';
			if (!disabledBtn) {
				const privateKey = generateAddressesFromSeed(phrase, phrase.length);
				const account = {
					name: name.trim(),
					phrase:
						phrase.split(' ').length > 2
							? CryptoJS.AES.encrypt(phrase, kitkat).toString()
							: '',
					privateKey:
						phrase.split(' ').length === 1
							? CryptoJS.AES.encrypt(phrase, kitkat).toString()
							: CryptoJS.AES.encrypt(privateKey, kitkat).toString(),
					backup: true,
				};
				dispatch(setData(account));
				dispatch(setCurrentWallet(name.trim()));
				dispatch(setIsLogin(true));
				dispatch(setBackup(true));
				dispatch(setDataWallet(null));
				dispatch(
					fetchDataWallet([
						CryptoJS.AES.encrypt(phrase, kitkat).toString(),
						false,
					])
				);
				setOpenModal(false);
				navigate('/manage');
			}
		}
	};

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className='title'>Import Wallet</div>
				<div className='subtitle'>
					Enter or paste below the 12 or 24 words from your recovery phrase,
					private key.
				</div>
				<Tags setPhrase={setPhrase} />
				<div className='page-align-bottom'>
					<Button
						variant={disabledBtn ? 'disabled' : 'default'}
						icon='import'
						onClick={() => setOpenModal(true)}
					>
						Import wallet
					</Button>
					<Button variant='inline' to={-1}>
						Cancel
					</Button>
				</div>
				<Popup
					isOpen={openModal}
					setIsOpen={setOpenModal}
					title='Enter wallet name'
				>
					<div className='page-row'>
						<Input
							inputValue={name}
							handleChange={setName}
							placeholder='Wallet Name'
							icon='wallet'
							size='lg'
						/>
						{showTextSame ? (
							<p style={{ color: 'var(--red)' }}>
								A wallet with the same name already exists
							</p>
						) : (
							<></>
						)}
					</div>
					<Button
						variant={
							name.length > 2 && !disabledBtnName ? 'default' : 'disabled'
						}
						icon='import'
						onClick={addNewAcc}
					>
						Import Wallet
					</Button>
					<Button variant='inline' onClick={() => setOpenModal(false)}>
						Cancel
					</Button>
				</Popup>
			</div>
		</div>
	);
};
