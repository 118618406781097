import React from 'react'
import styles from './backup.module.css'
import { Button } from '../../components'

import success from '../../assets/images/backup-success.png'

export const BackupSuccess = () => {
	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className={styles.logo}>
					<img src={success} alt='Backup Success' />
					<div className={styles.title}>Manual backup successful</div>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' to='/settings-account'>
					Done
				</Button>
			</div>
		</div>
	)
}
