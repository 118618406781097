import React from 'react'
import { Button, CopyButton, FormColumns } from '../../components'
import styles from './mnemonicWords.module.css'
import Svg from '../../svgs/Svg'
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'
const kitkat = 'Qsx@ah&OR82WX9T6gCt'

export const MnemonicWords = () => {
	const { dataUser, currentWallet } = useSelector((state) => state.storage)
	const [currentWalletData, setCurrentWalletData] = React.useState(undefined)

	React.useEffect(() => {
		if (dataUser.length && currentWallet !== '') {
			setCurrentWalletData(dataUser.find((item) => item.name === currentWallet))
		}
	}, [dataUser, currentWallet])

	return (
		<div className='page'>
			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Save your phrase</div>
				</div>

				<div className='subtitle'>
					Write this 12 words carefully, or save them to a password manager.
				</div>
				{currentWalletData !== undefined ? (
					<>
						<div className={styles.row}>
							<FormColumns
								disabled
								inputsList={CryptoJS.AES.decrypt(
									currentWalletData.phrase,
									kitkat
								)
									.toString(CryptoJS.enc.Utf8)
									.split(' ')}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 15,
							}}>
							<CopyButton
								phrase={CryptoJS.AES.decrypt(
									currentWalletData.phrase,
									kitkat
								).toString(CryptoJS.enc.Utf8)}
								text='Copy phrase'
							/>
						</div>
					</>
				) : (
					<></>
				)}
				<div className={styles.block}>
					<Svg type='alert' />
					<span>Never share your recovery phrase with anyone.</span>
				</div>

				<Button variant='default' to='/backup-finish'>
					Next
				</Button>
				<Button variant='inline' to={-1}>
					Cancel
				</Button>
			</div>
		</div>
	)
}
