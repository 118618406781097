import React from 'react'
import styles from './settings.module.css'
import { ItemBlock, Toggle, Header } from '../../components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const Settings = () => {
	const navigate = useNavigate()
	const { usePin } = useSelector((state) => state.storage)

	const list = [
		{
			title: 'Use PIN Code',
			icon: 'lock',
		},
	]

	const handleToggle = () => {
		if (usePin) {
			navigate('/login-pin', { state: { to: '/settings' } })
		} else {
			navigate('/create-pin', { state: { to: '/settings' } })
		}
	}

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header to='/settings-account' title='Security' />

				<div className={styles.list}>
					{list.map((item, index) => (
						<ItemBlock content={item} key={index}>
							<Toggle
								data={usePin}
								checked={usePin}
								id={`safety` + index}
								onToggle={handleToggle}
							/>
						</ItemBlock>
					))}
				</div>
			</div>
		</div>
	)
}
